import React from 'react'
import { NavLink } from 'react-bootstrap'
import studio from '../assets/logo.png'
import { Link } from 'react-router-dom'

const Logoset = () => {
  return (
    <div className='studio_logo'>
      <NavLink as={Link} to={"/"}>
        <img src={studio} alt="RST" />
      </NavLink>
    </div>
  )
}

export default Logoset;