import React from "react";
import coloredline from "../assets/coloredline.jpg";

const Footer = () => {
  return (
    <div>
      <footer data-aos="fade-up">
        <div class="container mb-5">
          <div class="row">
            <div class="col-sm-4">
              <h5 className="font-semibold text-[#00ffff]">About Us</h5>
              <p className="text-base leading-6">
                Our expert development team works on the cutting edge of
                technology and best practices. We make websites built for
                performance. Our methodology is proven for each industry we
                serve. We provide a dedicated team of specialized experts.
              </p>
            </div>
            <div class="col-sm-5 footer-servs-ul">
              <h5 className="font-semibold text-[#00ffff]">Our Services</h5>
              <ul className="columns-2 list-none text-base p-0">
                <li>
                  <a href="#" >Company</a>
                </li>
                <li>
                  <a href="#">Web Designing</a>
                </li>
                <li>
                  <a href="#">Web development</a>
                </li>
                <li>
                  <a href="#">SEO</a>
                </li>
                <li>
                  <a href="#">Social Media Marketing</a>
                </li>
                <li>
                  <a href="#">Web Hosting</a>
                </li>
                <li>
                  <a href="#">Domain Registration</a>
                </li>
                <li>
                  <a href="#">PPC Marketing</a>
                </li>
                <li>
                  <a href="#">Content Marketing</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 footer-servs-ul">
              <h5 className="font-semibold text-[#00ffff]">Privacy &amp; Policy</h5>
              <ul className="columns-1 list-none text-base p-0">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Refund Policy</a>
                </li>
                <li>
                  <a href="#">Terms &amp; Condition</a>
                </li>
                {/* <li><a href="#">Pay Now</a></li> */}
                <div className="col-lg-2 col-sm-6 top-details">
                  <div className="top-content">
                    <a href="#">
                      {/* <button class="btn" data-toggle="modal">PayNow</button> */}
                    </a>
                  </div>
                </div>
              </ul>
              <a href="#"></a>
            </div>
            <a href="#"></a>
          </div>
          <a href="#"></a>
        </div>
        <a href="#"></a>
        <div className="bg-[#414141] text-center">
          <div className=" colored-line relative w-full h-1 flex overflow-hidden col-12">
            <img src={coloredline} alt="line" width="100%"></img>
          </div>

          <a href="#"></a>
          <div className="container  py-1.5 tech">
            <a href="#">
              <h3 className="mt-2  p-[10px]">RS Technologies</h3>
            </a>
            <p >
              <a href="#">
                © Copyright 2022 All Right Reserved. 
              </a>
              <a href="#"></a>
            </p>
          </div>
        </div>
      </footer>

      {/* <div className="footer">
        <div className="colored-line d-flex col-12">
          <img src={coloredline} alt="line" width="100%"></img>
        </div>
        <p>Copyrights© 2022 RS Technologies. All rights reserved.</p>
      </div> */}
    </div>
  );
};

export default Footer;
