import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import contactform from "../assets/contact-form-bg.jpg";
import Footer from "../components/Footer";
import whatsapp from "../assets/whatsapp.png";
import facebook from "../assets/facebook.jpg";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";
import bg_footer from "../assets/bg-footer.jpg";
import instagram from "../assets/instagram.jpg";
import google from "../assets/google.jpg";
// import { FaBeer } from 'react-icons/fa';
import Icon from "../components/Icon";


const Contact = () => {
  return (
    <div>
       <div className="homepage_align">
        <Logoset />
        <Navpart />
        <Icon />
      </div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>

      <div className="heading_align">
        <div className="home_bg">
          <div class="container">
            <h2 class="title">
              <span class="text">CONTACT US</span>
            </h2>
          </div>
          {/* <div>
                <h1>SEO/DIGITAL MARKETING</h1>
            </div> */}
        </div>
      </div>

      {/* <div className="title_card mb-5">
        <img src={contact} alt="contacts" className=""></img>
        <h1>CONTACT US</h1>
      </div> */}

      <div className=""></div>
      {/* <div className="container portfolio">
        <h1>Let’s Talk</h1>
        <p>
          Let’s Talk services are free and if you have a comment, concern or
          compliment about our services, please call us on our phone number.
          We’re never too far away, whether you want to discuss your
          requirements...
        </p>
      </div>
      <div className="row container-fluid p-0">
        <div className="contact_form">
         <img src={contactform} alt="contact_form" />
        </div>
        <div className=" contact_address">
          <div className="row">
            <div className="col-sm-6 location_set">
              <h2>Location</h2>
              <div class="mt-2 contact-blk">
                <h3>RS Technologies</h3>
                <p>
                  1570A, 2nd Floor, PKN Road,
                  <br />
                  Sivakasi, Tamilnadu, <br />
                  India - 626189
                </p>
              </div>
            </div>
            <div class="col-sm-6 contact_blk">
              <h2>Contact Information</h2>
              <div class="mt-2">
                <h3>Email</h3>
                <p class="contact_link">
                  <a href="mailto:contact@rajaastudio.com">
                    contact@rstechnologies.com
                  </a>
                  <br />
                </p>
                <h3>Phone</h3>
                <h5>
                  +91 9916 555 864
                  <a href="https://web.whatsapp.com/" target="_blank"></a>
                  <br />
                  +91 9345 220 676
                </h5>
                <div className="social_icons">
                  <a href="https://web.whatsapp.com/">
                  <img
                    src={whatsapp}
                    alt="Whatsapp"
                    width="50px"
                    height="50px"
                  />
                  </a>
                  <a href="https://www.facebook.com/">
                  <img
                    src={facebook}
                    alt="facebook"
                    width="40px"
                    height="40px"
                  /></a>
                  <a href="https://twitter.com/">
                  <img src={twitter} alt="twitter" width="40px" height="40px" />
                  </a>
                  <a href="https://www.instagram.com/">
                  <img
                    src={instagram}
                    alt="instagram"
                    width="40px"
                    height="40px"
                  /></a>
                  <a href="https://in.linkedin.com/company/rajaa-studio">
                  <img
                    src={linkedin}
                    alt="linkedin"
                    width="50px"
                    height="50px"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section className="main-contact-information m-5">
        <div className="container" style={{ padding: "" }}>
          <div className="row">
            <div className="col-12 pb-3 ">
              <h3 className="font-semibold text-orange-900">Contact information</h3>
              <p className="text-justify">
                Let’s Talk services are free and if you have a comment, concern
                or compliment about our services, please call us on our phone
                number. We’re never too far away, whether you want to discuss
                your requirements...
                {/* growth <br className="d-none d-md-block" />
              happenings of Happenize. */}
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6">
              <div className=" mb-4 mb-lg-0">
                <h5 className="font-semibold text-1xl text-orange-900">Find us</h5>
                <p>
                  <a className="no-underline text-black" href="" target="_blank">
                    <strong className="text-black">RS Technologies</strong> 1570A, 2nd Floor, <br />{" "}
                    PKN Road, Sivakasi, Tamilnadu, <br />
                    India - 626123
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className=" mb-4 mb-lg-0">
                <h5 className="font-semibold text-1xl text-orange-900">Contact us</h5>
                <a href="tel:+91 9916 555 864" className="d-block no-underline text-black">
                  <strong className="text-black">Telephone:</strong> +91 9916 555 864
                </a>
                <a href="tel:+91 9345 220 676" className="d-block no-underline text-black">
                  <strong className="text-black">Fax:</strong> +91 9345 220 676
                </a>
                <a href="mailto:contact@rstechnologies.com" className="d-block no-underline text-black">
                  <strong className="text-black"> Email:</strong> contact@rstechnologies.com
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="mb-4 mb-lg-0">
                <h5 className="font-semibold text-1xl text-orange-900">Follow us</h5>
                <a
                  href="https://www.facebook.com/happenize"
                  target="_blank"
                  className="me-2"
                >
                  <div className="flex justify-between items-center my-4 mx-0 ">
                    <a href="https://web.whatsapp.com/">
                      <img
                        src={whatsapp}
                        alt="Whatsapp"
                        width="50px"
                        height="50px"
                      />
                    </a>
                    <a href="https://www.facebook.com/">
                      <img
                        src={facebook}
                        alt="facebook"
                        width="40px"
                        height="40px"
                      />
                    </a>
                    <a href="https://twitter.com/">
                      <img
                        src={twitter}
                        alt="twitter"
                        width="40px"
                        height="40px"
                      />
                    </a>
                    <a href="https://www.instagram.com/">
                      <img
                        src={instagram}
                        alt="instagram"
                        width="40px"
                        height="40px"
                      />
                    </a>
                    <a href="https://in.linkedin.com/company/rajaa-studio">
                      <img
                        src={linkedin}
                        alt="linkedin"
                        width="50px"
                        height="50px"
                      />
                    </a>
                  </div>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 24 24">
                  <defs>
                    <clipPath id="a">
                      <rect width="24" height="24" fill="none"></rect>
                    </clipPath>
                  </defs>
                  <rect width="24" height="24" fill="none"></rect>
                  <g clip-path="url(#a)">
                    <path d="M1.1,0A1.1,1.1,0,0,0,0,1.1V18.9A1.1,1.1,0,0,0,1.1,20h9.58V12.25H8.08v-3h2.6V7a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H15.3c-1.26,0-1.5.6-1.5,1.47V9.22h3l-.39,3H13.8V20h5.1A1.1,1.1,0,0,0,20,18.9V1.1A1.1,1.1,0,0,0,18.9,0H1.1Z" transform="translate(2 2)" fill="#333"></path>
                  </g>
                </svg> */}
                </a>
                <a
                  href="https://www.instagram.com/happenize/"
                  target="_blank"
                  className="me-2"
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 24 24">
                  <defs>
                    <clipPath id="a">
                      <rect width="24" height="24" fill="none"></rect>
                    </clipPath>
                  </defs>
                  <rect width="24" height="24" fill="none"></rect>
                  <g clip-path="url(#a)">
                    <path d="M10,20c-2.712,0-3.06,0-4.12-.059a7.293,7.293,0,0,1-2.43-.471A4.81,4.81,0,0,1,1.68,18.32,4.727,4.727,0,0,1,.53,16.55a7.27,7.27,0,0,1-.47-2.43C0,13.06,0,12.712,0,10S0,6.94.06,5.88A7.27,7.27,0,0,1,.53,3.45,4.716,4.716,0,0,1,1.68,1.68,4.811,4.811,0,0,1,3.45.53,7.269,7.269,0,0,1,5.88.06C6.94,0,7.288,0,10,0s3.06,0,4.12.06a7.27,7.27,0,0,1,2.43.47,4.727,4.727,0,0,1,1.77,1.15A4.964,4.964,0,0,1,19.48,3.45a7.581,7.581,0,0,1,.461,2.43C20,6.94,20,7.288,20,10c0,1.674-.022,2.432-.04,3.041-.011.375-.02.672-.02,1.079a7.581,7.581,0,0,1-.461,2.43,4.88,4.88,0,0,1-1.159,1.77,4.735,4.735,0,0,1-1.77,1.149,7.293,7.293,0,0,1-2.43.471C13.06,20,12.712,20,10,20ZM6.04,18.1h0c1,.06,1.342.06,4,.06,2.6,0,2.984-.009,4-.06a5.6,5.6,0,0,0,1.86-.34,3.2,3.2,0,0,0,1.149-.75,3.081,3.081,0,0,0,.751-1.15A5.61,5.61,0,0,0,18.14,14c.05-1.02.059-1.408.059-4s-.009-2.981-.059-4a5.588,5.588,0,0,0-.34-1.9A3.04,3.04,0,0,0,17.05,3a3.078,3.078,0,0,0-1.19-.8A5.586,5.586,0,0,0,14,1.86c-1-.06-1.342-.06-4-.06-1.626,0-2.4.024-2.97.041-.362.011-.648.02-1.03.02A5.706,5.706,0,0,0,4.1,2.2,3.128,3.128,0,0,0,3,3,3.017,3.017,0,0,0,2.21,4.1,5.505,5.505,0,0,0,1.86,6C1.8,7,1.8,7.342,1.8,10c0,1.627.024,2.4.041,2.97.011.362.02.649.02,1.031a5.513,5.513,0,0,0,.4,1.9A3.024,3.024,0,0,0,3,17.05a3.245,3.245,0,0,0,1.1.751,5.718,5.718,0,0,0,1.83.3Zm3.971-2.97A5.13,5.13,0,0,1,10,4.87h.02a5.13,5.13,0,0,1-.009,10.26ZM10,6.67a3.33,3.33,0,1,0,1.275,6.406A3.331,3.331,0,0,0,13.33,10,3.331,3.331,0,0,0,10,6.67Zm5.343-.81a1.2,1.2,0,0,1-1.179-.966,1.2,1.2,0,1,1,1.179.966Z" transform="translate(2 2)" fill="#333"></path>
                  </g>
                </svg> */}
                </a>
                <a
                  href="https://www.linkedin.com/company/happenize"
                  target="_blank"
                  className="me-2"
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 24 24">
                  <defs>
                    <clipPath id="a">
                      <rect width="24" height="24" fill="none"></rect>
                    </clipPath>
                  </defs>
                  <rect width="24" height="24" fill="none"></rect>
                  <g clip-path="url(#a)">
                    <path d="M20,21.6H1.6A1.574,1.574,0,0,1,0,20.052V1.547A1.574,1.574,0,0,1,1.6,0H20a1.574,1.574,0,0,1,1.6,1.547v18.5A1.574,1.574,0,0,1,20,21.6ZM13.4,10.657c1.366,0,1.652,1.2,1.652,2.206v5.218h3.262V12.488A4.723,4.723,0,0,0,17.239,9.17,3.544,3.544,0,0,0,14.556,8.1a3.222,3.222,0,0,0-2.94,1.612V8.329H8.353c.043.9,0,9.663,0,9.752h3.262V12.633a2.113,2.113,0,0,1,.109-.79A1.782,1.782,0,0,1,13.4,10.657ZM3.285,8.329v9.752H6.548V8.329Zm1.652-4.7A1.69,1.69,0,1,0,4.9,7h.022a1.691,1.691,0,1,0,.021-3.37Z" transform="translate(1.086 1.346)" fill="#333"></path>
                  </g>
                </svg> */}
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className=" text-1xl mb-lg-0">
                <h5 className="font-semibold text-1xl text-orange-900">Work time</h5>
                <p className="mb-0">
                  <strong>Mon – Friday :</strong> 9:00 am- 8:00 pm
                </p>
                <p className="mb-0">
                  <strong>Saturday:</strong> 10:00 am - 5:00 pm
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h5 class="font-semibold text-2xl uppercase flex justify-center">Location</h5>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11708.357545221881!2d77.78750282602357!3d9.464443720362846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cfe1f70c6a99%3A0x667cef001d8988b5!2sRS%20Technologies!5e0!3m2!1sen!2sin!4v1653996706939!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0, marginBottom: 20 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4014583.331431716!2d76.41784068723793!3d10.696519108359498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cfe1f70c6a99%3A0x667cef001d8988b5!2sRS%20Technologies!5e0!3m2!1sen!2sin!4v1653993992824!5m2!1sen!2sin" width="100%" height="450" style={{border:0, marginBottom:20}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
          <div className="col-lg-6 col-md-6">
            <div>
              <div class="contact-form-wrapper d-flex justify-content-center">
                <form action="#" class="contact-form">
                  <h5 class="font-semibold text-2xl uppercase flex justify-center">Get in Touch</h5>
                  <p class="">
                    Feel free to contact us if you need any assistance, any help
                    or another question.
                  </p>
                  <div>
                    <input
                      type="text"
                      class="form-control rounded border-white mb-3 form-input"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control rounded border-white mb-3 form-input"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      id="message"
                      class="form-control rounded border-white mb-3 form-text-area"
                      rows="5"
                      cols="30"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div class="submit-button-wrapper">
                    <input type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Contact;
