import React from 'react'
import { BsTelephoneFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";


const Icon = () => {
  return (
    <div className="icons_top">
        <a href="tel:+91 9345 220 676" ><BsTelephoneFill /></a>
        <a href="mailto:contact@rstechnologies.com" ><AiOutlineMail /></a>
        <a href="https://goo.gl/maps/ZMuJDmaw4SpAqNx79" target="_blank"><MdLocationOn /></a>
    </div>
  )
}

export default Icon