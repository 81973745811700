import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import mobileapp from "../assets/mobileapp.jpg";
import react from "../assets/react.jpg";
import flutter from "../assets/flutter.jpg";
import android from "../assets/android.jpg";
import ios from "../assets/ios.jpg";
import Footer from "../components/Footer";
import appdev from "../assets/app-dev.png";
import Icon from "../components/Icon";


const Mobapp = () => {
  return (
    <div>
       <div className="homepage_align">
        <Logoset />
        <Navpart />
        <Icon />
      </div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>

      <div className="mt-10">
        <div className="home_bg">
          <div className="container">
            <h2 className="text-2xl font-extrabold uppercase flex justify-center p-5">
              <span className="text">MOBILE APPS DEVELOPMENT</span>
            </h2>
          </div>

          <div className="">
              <img src={appdev} alt="appdev" className="w-full" />
          </div>

          {/* <div>
                <h1>SEO/DIGITAL MARKETING</h1>
          </div> */}
        </div>
      </div>


      {/* <div className="title_card">
        <img src={contact} alt="contacts"></img>
        <h1>MOBILE APPLICATION DEVELOPMENT</h1>
      </div> */}

      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-sm-5 contact_address">
            <img src={mobileapp} alt="mobapp" className="w-full p-0 mt-5 pt-2" />
            <div className="technology">
              <h2 className="flex justify-center">Technology Stack</h2>
            </div>
            <div className="softwares">
              <img src={react} alt="react" className="soft_tech" />
              <img src={flutter} alt="flutter" className="soft_tech" />
              <img src={android} alt="react" className="soft_tech" />
              <img src={ios} alt="react" className="soft_tech" />
            </div>
          </div>
          <div className="col-sm-7 contact_address">
            <div>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 mt-5 pt-2">
                Our expert development team works on the cutting edge of
                technology and best practices. We make websites built for
                performance. Our methodology is proven for each industry we
                serve. We provide a dedicated team of specialized experts.
              </p>
            </div>
            <div className="">  
              <h3 className="text-xl font-semibold text-[#787878]">Responsiveness</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              Our Mobile App Development team is committed to design & present customized mobile apps that are the key for ultimate branding success. These innovative mobile apps can lure customers, engage them, promote a product, consolidate reports, ease the workload and so much more. There are no limits to what can be accomplished by our specialists in the Mobile App Development team!
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">Smart features for your Smartphones</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              With years of experience, our talented Mobile App Development team ensures the use of design architecture that raises the bar for Mobile Apps. As experts in Android App Development as well as iOS App Development, we incorporate features to streamline operations and keep the app user friendly with easy maneuverability.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">Inclusive Coding</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              The Mobile App Development team has mastered the art of combining expertise & experience to give a seamless Mobile App experience.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">Recall Value</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              A well planned mobile app places your brand in direct contact with your customers who are always on the move. This includes Android Apps as well as iOS Apps.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">Maximize Your Reach</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0  pt-2">
              Our Mobile App Development team has been analyzing strategies that could help fulfill your brand’s mission in this digital age where continuos evolution is the winning mantra. An intuitive mobile app designed for iOS users or Android users, will help maximize your reach as well as Return on Investment.
              </p>

            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Mobapp;
