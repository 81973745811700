import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Work from "./pages/Work";
import Webapp from "./pages/Webapp";
import Mobapp from "./pages/Mobapp";
import Contact from "./pages/Contact";
import Branding from "./pages/Branding";
import Modelling from "./pages/Modelling";
import Digitalmarketing from "./pages/Digitalmarketing";


function App() {
  return (
    <div className="body">
      <BrowserRouter>
        <Routes>
          <Route index element={<Homepage />} />
              {/* <Route exact path="/">
            <Home />
          </Route> */}
          <Route path="/Work" element={<Work />} />
          <Route path="/Webapp" exact element={<Webapp />} />
          <Route path="/Mobapp" exact element={<Mobapp />} />
          <Route path="/Modelling" exact element={<Modelling />} />
          <Route path="/Branding" exact element={<Branding />} />
          <Route path="/Digitalmarketing" exact element={<Digitalmarketing />}/>
          <Route path="Contact" exact element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
