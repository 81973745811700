import React from 'react'
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import model from "../assets/3d.jpg";
import modelmax from "../assets/3ds-max.jpg";
import modelmaya from "../assets/maya.jpg";
import Footer from '../components/Footer';
import marketing from "../assets/model.png";  
import Icon from "../components/Icon";



const Modelling = () => {
  return (
    <div>
       <div className="homepage_align">
        <Logoset />
        <Navpart />
        <Icon />
      </div>
     <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>

      <div className="mt-20">
        <div className="">
          {/* <div class="container">
            <h2 class="title">
              <span class="title-word title-word-1">3D </span>
              <span class="title-word title-word-2">MODELING </span>
              <span class="title-word title-word-3">AND </span>
              <span class="title-word title-word-4">ANIMATION</span>
            </h2>
          </div> */}
          {/* <div>
                <h1>SEO/DIGITAL MARKETING</h1>
            
            
          </div> */}

          <div className="">
            <h2 class="text-2xl font-extrabold uppercase flex justify-center">
              <span class="text">3D Modelling</span>
            </h2>
              <img src={marketing} alt="digital" className='w-full rounded-full' />
          </div>
        </div>
      </div>

      {/* <div className="title_card">
        <img src={contact} alt="contacts"></img>
        <h1>3D MODELING AND ANIMATION</h1>
      </div> */}


      <div className="container-fluid">
        <div className="row p-3 mt-3">
          <div className="col-sm-5 contact_address_pic">
            <img src={model} alt="webapp" className="w-full p-0 mt-5 pt-2" />
            <div className=" mt-3">
              <h2 className='flex justify-center'>Technology Stack</h2>
            </div>
            <div className="softwares">
                <img src={modelmax} alt="modelmax" />
                <img src={modelmaya} alt="modelmaya" />
            </div>
          </div>
          <div className="col-sm-7">
            <div>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              We provide unparalleled 3D modeling services to various businesses across the globe since decades. 
              We are accustomed to handling diverse client requirements and we are adept at the use of sophisticated 3D rendering software so as to make 3D models that would serve the unique needs of a project, 
              however complex it might be. As one of the most reliable 3D modeling companies,
               we base our operations on strong foundations of transparency and ethics and offer the most competitive 3D modeling rates.
              </p>
            </div>
            <div className="app_dev">
              <h3 className="text-xl font-semibold text-[#787878]">3D Product Modeling</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              Product Modeling is an important part especially for ecommerce in this day and age. 
              We have experience in modeling consumer electronics to vehicles for presentation and videos.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">3D Architecture</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              In the past years, we have done several projects involving architecture.
               Prototyping upcoming real estate projects, or interior planning,
               we’ve covered it all.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">3D Environment & Props</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
                The objective of our Web Development team is to help you connect
                with prospective clients, develop leads and optimize this
                digital opportunity to nurture them into loyalists.
              </p>
              <h3 className="text-xl font-semibold text-[#787878]">Analytic Approach</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 pt-2">
              Creating 3D environments and props for a project requires high attention to detail in order to create the
               right mood for the project. 
              We understand the dynamics of giving the right feel for a place
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
   

export default Modelling;