import React from "react";
import { Nav, NavDropdown, Container, Navbar } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const Navpart = () => {
  return (
    <div>
      <div className="nav-align ">
        <Navbar expand="lg" className="is-fixed-top">
          <Container>
            {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <div>
                  <Nav.Link as={Link} to={"/Work"}>
                    WORK
                  </Nav.Link>
                </div>
                <div className="navigation_fx">
                <NavDropdown title="SERVICES" id="basic-nav-dropdown">
                    <NavDropdown.Item>
                      <Nav.Link as={Link} to={"/Webapp"}>
                        WEB APPS DEVELOBMENT
                      </Nav.Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.2">
                      <Nav.Link as={Link} to={"/Mobapp"}>
                        MOBILE APPS DEVELOBMENT
                      </Nav.Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.3">
                      <Nav.Link as={Link} to={"/Modelling"}>
                        3D MODELLING & ANIMATION
                      </Nav.Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.4">
                      <Nav.Link as={Link} to={"/Branding"}>
                        BRANDING
                      </Nav.Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.5">
                      <Nav.Link as={Link} to={"/Digitalmarketing"}>
                        DIGITAL MARKETING
                      </Nav.Link>
                    </NavDropdown.Item>
                </NavDropdown>
                </div>

                <Nav.Link as={Link} to={"/Contact"}>
                  CONTACT US
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Navpart;
