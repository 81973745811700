import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import branding from "../assets/branding.png";
import adobeai from "../assets/adobeai.jpg";
import adobeps from "../assets/adobeps.jpg";
import Footer from "../components/Footer";
import Icon from "../components/Icon";
import brandingbg from "../assets/branding_bg.jpg"


const Branding = () => {
  return (
    <div>
       <div className="homepage_align" >
        <Logoset />
        <Navpart />
        <Icon />
      </div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>
      <div className="mt-20 pt-2">
        <div className="">
          <div class="">
            <h2 className="text-2xl font-extrabold uppercase flex justify-center">
            <span className="text -mt-10">BRANDING</span>
            </h2>
          </div>
          {/* <img src={brandingbg} alt="Branding" width="100%"></img>  */}
          </div>
      </div>

      {/* <div className="title_card">
          <img src={contact} alt="contacts"></img>
          <h1>BRANDING</h1>
        </div> */}
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-sm-5 pb-10">
            <img src={branding} alt="mobapp" className="branding_img" width="100%" />
            <div className="flex justify-center">
              <h2>Technology Stack</h2>
            </div>
            <div className="softwares">
              <img src={adobeai} alt="react" className="border-2 border-e1e1ee1-600 m-2" />
              <img src={adobeps} alt="flutter" className="border-2 border-#e1e1ee1-600 m-2" />
            </div>
          </div>
          <div className="col-sm-7 pb-10">
            <div>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red p-0 mt-5 pt-2">
                The design team has to develop distinct visual strategies at the
                first stage; each one emphasized a different aspect of the
                positioning brief. The final logo design will be an evolutionary
                leap from the old logo or from concept. The central idea behind
                the new logo would reflect the client’s business strategy of
                selling more than just service or specific product. The design
                team will connect the idea a of "Brand" name. This approach may
                communicate "your BRAND sells everything from A to Z." The
                graphic design may also speak about the brand positioning:
                customer focus and friendly service or product.
              </p>
            </div>
            <div className="">
              <h3 className="text-xl font-bold text-dark-red">Logo Design</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red">
                A strong logo projects the ideology that guides your brand. It
                also sets the tone for the rest of your marketing. Corporate
                Identity: Corporate Identity benefits an organization in myriad
                ways. An organization that invests in developing a solid
                corporate identity says to its customer "we are here to stay."
                It's a sign of longevity, which is not only attractive to
                customers, but also is desirable to potential investors. It's an
                indicator that an is serious about being successful, that it is
                reliable in the industry.
              </p>
              <h3 className="text-xl font-bold text-dark-red">Profile Design</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red">
                We manage the entire process of Company Profile Designing,
                Copywriting, Photography, Printing and Delivering.
              </p>
              <h3 className="text-xl font-bold text-dark-red">Brochure Design</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red">
                When it comes to presentation and client relationship, every
                company should have their sales kit brochures or marketing kit
                such as product brochure, sales brochure or services pamphlet
                and catalogues. A brochure allows you to store more detailed
                information about your company or organization. Our creative
                graphics services will add extra value to your product or
                service brochures.
              </p>
              <h3 className="text-xl font-bold text-dark-red">Flyer Design</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red">
                A creative flyer design gets your brand or business noticed.
              </p>
              <h3 className="text-xl font-bold text-dark-red">Catalogue Design</h3>
              <p className="text-xl tracking-wider leading-8 text-start text-dark-red">
                Catalogue is the best tool to present all your products and its
                features. Its the most preferable sales team tool.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Branding;
