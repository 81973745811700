import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import seo from "../assets/seo.jpg";
import digital from "../assets/digital.jpg";
import Footer from "../components/Footer";
import coloredline from "../assets/coloredline.jpg";
// import digitalpic from "../assets/digitalpic.jpg";  
import digit_about from "../assets/digit_about.jpg";
import digitalpic from "../assets/marketing.png";  
import Icon from "../components/Icon";



const Digitalmarketing = () => {
  return (
    <div>
       <div className="homepage_align">
        <Logoset />
        <Navpart />
        <Icon />
      </div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>
      {/* <div className="colored-line d-flex col-12">
        <img src={coloredline} alt="line" width="100%"></img>
      </div> */}

      <div className="heading_align">
        <div className="home_bg">
          <div class="container-fluid p-0">
            <h2 class="title">
            <span class="text mb-3">DIGITAL MARKETING</span>
            </h2>
            <img src={digitalpic} alt="Marketting"  width="100%"/>
          </div>

          {/* <div className="d-flex justify-content-center digital">
              <img src={digital} alt="digital" width="500px"/>    
          </div> */}
          <div class="d-flex justify-content-center">
            {/* <div class="col-6 col-md-2"></div>
            <div class="col-6 col-md-8">
              <div className="d-flex justify-content-center digital">
                <img src={marketing} alt="digital"  />
              </div>
            </div>
            <div class="col-6 col-md-2"></div> */}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row p-3 mt-3">
          <div className=" col-sm-6 contact_address">
              <img src={digit_about} alt="digit_about" width="100%" className="mt-5" />
          </div>
          <div className=" col-sm-6">
            <div className="container Web_contact_blk">
              <p className="Web_contact p-0 mt-3 pt-2">
                There goes a famous saying 'The best page to hide dead bodies is
                on the second page of google'. With our SEO experts, we build
                your brand's online presence to improve its ranking on the
                search engine pages.This brings new customers to your site, more
                trust from your customers and ultimately lead generation and
                sales. Our expertise in organic search marketing campaigns will
                increase your ROI and strategically expand your online presence
                and clientele.
              </p>
            </div>
            <div className="container app_dev">
              <h3>Easy Access</h3>
              <p className="Web_contact">
                We ensure that your web pages and data are structured and easily
                accessible by the search engines
              </p>
              <h3>Optimizing with Keywords</h3>
              <p className="Web_contact">
                With our expertise, relevant content enriched with selective
                keywords adds value to your readers.
              </p>
              <h3>Maximum Outreach</h3>
              <p className="Web_contact">
                We link your brand with industry influencers and key websites
                through our strategized & value based outreach.
              </p>
              <h3>Stats that Speak</h3>
              <p className="Web_contact">
                We share participation metrics, like most valuable click, least
                valuable click and other statistics. We also include conversion
                metrics like new visitor conversion, return visitor conversion,
                value per visit, cost per conversion, bounce rate and exit
                pages.
              </p>
              <h3>Flexible Strategy</h3>
              <p className="Web_contact">
                With the advantage of us, you are equipped to revise your
                strategy based on analysis of the figures last year.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Digitalmarketing;
