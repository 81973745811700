import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import webapp from "../assets/web-app.jpg";
import html from "../assets/html.jpg";
import css from "../assets/css.jpg";
import js from "../assets/js.jpg";
import bootstrap from "../assets/bootstrap.jpg";
import angular from "../assets/angular.jpg";
import react from "../assets/react.jpg";
import dot_net_c from "../assets/dot-net-c.jpg";
import dot_net_v from "../assets/dot-net-vb.jpg";
import node from "../assets/node-js.jpg";
import python from "../assets/python.jpg";
import wordpress from "../assets/wordpress.jpg";
import mongodb from "../assets/mongodb.jpg";
import mysql from "../assets/mysql (1).jpg";
import webd5 from "../assets/webd5.jpg";
import webd2 from "../assets/webd2.jpg";
import webd6 from "../assets/webd6.jpg";
import webd7 from "../assets/webd7.jpg";
import Footer from "../components/Footer";
import Icon from "../components/Icon";
import {motion} from 'framer-motion'

const Webapp = () => {
  return (
    <div>
       <motion.div className="homepage_align"
         initial = {{y:-200}}
         animate = {{y:-10}}
       >
        <Logoset />
        <Navpart />
        <Icon />
      </motion.div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>

      <div className="heading_align">
        <div className="home_bg">
          <div class="container">
            <h2 class="title">
              <span class="text">WEB APPS DEVELOPMENT</span>
            </h2>
          </div>
          {/* <div>
                <h1>SEO/DIGITAL MARKETING</h1>
            </div> */}

          <div class="container">
            <h2 class="section-head">
              Development <span class="span">Process</span>
            </h2>
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <div class="col-12 web-des-box dev-box">
                  <div class="des-number">
                    <h2>01</h2>
                  </div>
                  <img
                    src={webd5}
                    alt="web"
                    className="Web_app p-0 mt-3 pt-2"
                  />
                  <h6>Analysis your requirments</h6>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="col-12 web-des-box dev-box">
                  <div class="des-number">
                    <h2>02</h2>
                  </div>
                  <img
                    src={webd2}
                    alt="web"
                    className="Web_app p-0 mt-3 pt-2"
                  />
                  <h6>Design your web layout</h6>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="col-12 web-des-box dev-box">
                  <div class="des-number">
                    <h2>03</h2>
                  </div>
                  <img
                    src={webd6}
                    alt="web"
                    className="Web_app p-0 mt-3 pt-2"
                  />
                  <h6>Development process in ASP/PHP or .NET</h6>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="col-12 web-des-box dev-box">
                  <div class="des-number">
                    <h2>04</h2>
                  </div>
                  <img
                    src={webd7}
                    alt="web"
                    className="Web_app p-0 mt-3 pt-2"
                  />
                  <h6>Secure Development process</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="title_card">
        <img src={contact} alt="contacts"></img>
        <h1>WEB APPLICATION DEVELOPMENT</h1>
      </div> */}

      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-sm-5 contact_address">
            <img src={webapp} alt="webapp" className="Web_app p-0 mt-5 pt-2" />
            <div className="technology">
              <h2 className="flex justify-center">Technology Stack</h2>
            </div>
            <div className="softwares flex flex-wrap justify-center">
              <img src={html} alt="html" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={css} alt="css" className="m-3 border-1 border-d6d6d6-600" />
              <img src={js} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={bootstrap} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={angular} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={react} alt="js" className="m-3 border-1 border-d6d6d6-600" />
              <img src={dot_net_c} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={dot_net_v} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={node} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={python} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={wordpress} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={mongodb} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
              <img src={mysql} alt="js" className=" m-3 border-1 border-d6d6d6-600" />
            </div>
          </div>
          <div className="col-sm-7 ">
              <p className=" text-lg tracking-normal text-start text-gray-400 leading-8 p-0 mt-5 pt-2">
                Our expert development team works on the cutting edge of
                technology and best practices. We make websites built for
                performance. Our methodology is proven for each industry we
                serve. We provide a dedicated team of specialized experts.
              </p>
            <div className="">
              <h3 className="font-semibold text-xl text-slate-600">Responsiveness</h3>
              <p className=" text-lg tracking-normal text-start text-gray-400 leading-8">
                The Web Development scenario is constantly evolving. The wide
                range of devices in use today include desktops, tablets and
                mobile phones. The need to look up a website can strike at any
                instant, and to ensure device compatibility, we design the most
                user friendly, responsive websites.
              </p>
              <h3 className="font-semibold text-xl text-slate-600">Optimized for Maximum ROI</h3>
              <p className=" text-lg tracking-normal text-start text-gray-400 leading-8">
                With our experience & expertise in Web Development, we ensure
                the use of right keywords that result in maximum page hits.
                Simply said, our designs are paired with Search Engine Optimized
                (SEO) & Value based content
              </p>
              <h3 className="font-semibold text-xl text-slate-600">Contextual Call to Action</h3>
              <p className=" text-lg tracking-normal text-start text-gray-400 leading-8">
                The objective of our Web Development team is to help you connect
                with prospective clients, develop leads and optimize this
                digital opportunity to nurture them into loyalists.
              </p>
              <h3 className="font-semibold text-xl text-slate-600">Analytic Approach</h3>
              <p className=" text-lg tracking-normal text-start text-gray-400 leading-8">
                As a Web Development Company proficient in 360° digital
                solutions, we understand the importance of statistical and
                analytical reports supportedby conversion metrics to measure
                success. Our customized reports ensure the best support system
                to boost your digital brand identity & capital.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Webapp;
