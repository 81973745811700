import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import Heading from "../components/Heading";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import Icon from "../components/Icon";
import {motion} from 'framer-motion'


// import Strategy from "../assets/strategy.jpg";

const Homepage = () => {
  return (
    <div>
      <motion.div className="homepage_align"
        initial = {{y:-200}}
        animate = {{y:-10}}
      >
        <Logoset />
        <Navpart />
        <Icon />
      </motion.div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>
      <div className="heading_align">
        <Heading />
      </div>
      {/* <div>
          <Cards></Cards>
      </div> */}
      {/* <div>
       <Footer></Footer>
       </div> */}
    </div>
  );
};

export default Homepage;

