import React from "react";
import Logoset from "../components/Logoset";
import Navpart from "../components/Navpart";
import contact from "../assets/contact-bg.jpg";
import dryhill from "../assets/dryhill.jpg";
import futsal from "../assets/futsal.jpg";
import gahomes from "../assets/gahomes.jpg";
import house from "../assets/hp.jpg";
import ipay from "../assets/ipay.jpg";
import isp from "../assets/isp.png";
import king from "../assets/kingcontentagency.jpg";
import nfp from "../assets/nfp_ci.jpg";
import nithy from "../assets/nithysjk.jpg";
import spy from "../assets/spy.jpg";
import turbo from "../assets/turborecruit.jpg";
import vtech from "../assets/vtech_branding.jpg";
import Footer from "../components/Footer";
import webd1 from "../assets/webd1.jpg";
import webd2 from "../assets/webd2.jpg";
import webd3 from "../assets/webd3.jpg";
import webd4 from "../assets/webd4.jpg";
import { FaGenderless } from "react-icons/fa";
import Icon from "../components/Icon";
import { motion } from "framer-motion";

const Work = () => {
  return (
    <div>
      <motion.div
        className="homepage_align"
        initial={{ y: -200 }}
        animate={{ y: -10 }}
      >
        <Logoset />
        <Navpart />
        <Icon />
      </motion.div>
      <div className="homepage_mobile">
        <Navpart />
        <Logoset />
        <Icon />
      </div>
      <div className="heading_align">
        <div className="home_bg">
          <div className="container">
            <h2 className="title">
              <span class="text">WORK</span>
              {/* <span className="title-word title-word-4">MARKETING</span> */}
            </h2>
          </div>
        </div>
      </div>
      <section className="design-process mb-5">
        <div className="container">
          <h1 className="section-head mb-5">
            Designing{" "}
            <span className="font-semibold text-red-700">Process</span>
          </h1>
          <div className="row">
            <motion.div className="col-lg-3 col-sm-6"
              initial = {{opacity:0}}
              animate = {{opacity:1}}
              transition = {{delay:0.1, duration:1}}
            >
              <div className="col-12 web-des-box mb-3">
                <div className="des-number">
                  <h1>01</h1>
                </div>
                <img src={webd1} alt="web" className="Web_app p-0 mt-5 pt-2" />
                <h6 className="process">Plan</h6>
                <ul className="seo-dash-ul"
                  initial = {{opacity:0}}
                  animate = {{opacity:1}}
                  transition = {{delay:1.5, duration:3}}>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Research
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Observe
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Understand
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Analyze
                  </li>
                </ul>
              </div>
            </motion.div>
            <motion.div className="col-lg-3 col-sm-6"
              initial = {{opacity:0}}
              animate = {{opacity:1}}
              transition = {{delay:0.1, duration:2}}
            >
              <div className="col-12 web-des-box mb-3">
                <div className="des-number">
                  <h1>02</h1>
                </div>
                <img src={webd2} alt="web" className="Web_app p-0 mt-5 pt-2" />
                <h6 className="process">Design</h6>
                <ul className="seo-dash-ul">
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Use Cases
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Business Process
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Wireframes
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Design Concepts
                  </li>
                </ul>
              </div>
            </motion.div>
            <motion.div className="col-lg-3 col-sm-6"
              initial = {{opacity:0}}
              animate = {{opacity:1}}
              transition = {{delay:0.1, duration:3}}
            >
              <div className="col-12 web-des-box mb-3">
                <div className="des-number">
                  <h1>03</h1>
                </div>
                <img src={webd3} alt="web" className="Web_app p-0 mt-5 pt-2" />
                <h6 className="process">Develop</h6>
                <ul className="seo-dash-ul">
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Design Handover
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Comunicate
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Monitor
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Visual Design
                  </li>
                </ul>
              </div>
            </motion.div>
            <motion.div className="col-lg-3 col-sm-6"
               initial = {{opacity:0}}
               animate = {{opacity:1}}
               transition = {{delay:0.1, duration:4}}
            >
              <div className="col-12 web-des-box mb-3">
                <div className="des-number">
                  <h1>04</h1>
                </div>
                <img src={webd4} alt="web" className="Web_app p-0 mt-5 pt-2" />
                <h6 className="process">Deploy</h6>
                <ul className="seo-dash-ul">
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Indiability
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Verification
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Design Quality Check
                  </li>
                  <li className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                    Approval
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* <div className="container-fluid">
        <div className="work">
          <div className="row">
            <div className="col-sm-4 p-0">
              <img src={dryhill} alt="dryhill" className="work_adj mt-5" />
            </div>
            <div className="col-sm-4 p-0">
              <img src={futsal} alt="Futsal" className="work_adj mt-5" />
            </div>
            <div className="col-sm-4 p-0">
              <img src={gahomes} alt="gahomes" className="work_adj mt-5" />
            </div>
            <div className="col-sm-4 p-0">
                <img src={house} alt="houseproduct" className="work_adj" />
            </div>
            <div className="col-sm-4 p-0">
                <img src={ipay} alt="ipayforward" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={isp} alt="ismart" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={king} alt="kingcontent" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={nfp} alt="newfreezing" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={nithy} alt="nithy" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={spy} alt="spy" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={turbo} alt="turborecruit" className="work_adj"/>
            </div>
            <div className="col-sm-4 p-0">
                <img src={vtech} alt="vtechbrand" className="work_adj"/>
            </div>
          </div>
        </div>
      </div> */}
      <Footer></Footer>
    </div>
  );
};

export default Work;
