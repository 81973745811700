import React from "react";
import { Carousel } from "react-bootstrap";
// import WebFont from "webfontloader";
import Software from "../assets/InternationalBusiness.png";
import Webdevelopment from "../assets/home-banner.jpg";
import MyVideo from "../assets/file.mp4";
import { FaForumbee } from "react-icons/fa";
// import Ideas from "../assets/idea.jpg";
import Ideas from "../assets/modeling.png";
// import Footer from "./Footer";
import Footer from "../components/Footer";
import Mobile from "../assets/Web_Development.png";


const Heading = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <img
            src={Webdevelopment}
            alt="Banner"
            className="p-0 banner-bg mb-5"
          ></img>
          {/* <video controls autostart autoPlay src={MyVideo} type="video/mp4" width="100%" height="100%" >
          </video> */}
        </div>

        <div className="banner_title">
          <h2 className="lg:text-8xl sm:text-2xl md:text-5xl text-white font-semibold uppercase">
            Big Ideas,
            <br /> Creative People, <br /> New Technologies.
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6" data-aos="fade-right">
            <strong>Global Professionals Network</strong>
            <h2 className="text-red-900 font-semibold text-3xl">World wide Business</h2>
            <p className="text-lg">
              The Web Development scenario is constantly evolving. The wide
              range of devices in use today include desktops, tablets and mobile
              phones. The need to look up a website can strike at any instant,
              and to ensure device compatibility, we design the most user
              friendly, responsive websites. The Web Development scenario is
              constantly evolving. The wide range of devices in use today
              include desktops, tablets and mobile phones. The need to look up a
              website can strike at any instant, and to ensure device
              compatibility, we design the most user friendly, responsive
              websites. The Web Development scenario is constantly evolving. The
              wide range of devices in use today include desktops, tablets and
              mobile phones. The need to look up a website can strike at any
              instant, and to ensure device compatibility, we design the most
              user friendly, responsive websites.
            </p>
          </div>
          <div className="col-sm-6 d-flex justify-content-center mb-5" data-aos="fade-left">
            <img
              src={Software}
              alt="software"
              width="100%"
              className="soft"
            ></img>
          </div>
        </div>
      </div>
      <div className="container-fluid ideas">
        <div className="row model_align">
          <div className="col-sm-6 d-flex justify-content-center model" data-aos="zoom-in-up">
            <img src={Ideas} alt="software" width="100%" className=""></img>
          </div>
          <div className="col-sm-6 mt-5" data-aos="fade-up">
            <strong>We create ideas worth</strong>
            <h2 className="text-red-900 font-semibold text-3xl">3D modeling </h2>
            <p className="text-lg">
              We provide unparalleled 3D modeling services to various businesses
              across the globe since decades. We are accustomed to handling
              diverse client requirements and we are adept at the use of
              sophisticated 3D rendering software so as to make 3D models that
              would serve the unique needs of a project, however complex it
              might be. As one of the most reliable 3D modeling companies, we
              base our operations on strong foundations of transparency and
              ethics and offer the most competitive 3D modeling rates.
            </p>
          </div>
        </div>
      </div>
      <section class="services">
        <div class="container mb-5">
          <div data-aos="zoom-in">
            <h2 class="section-head">
              Our <span class="span">Services</span>
            </h2>
          </div>

          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="">
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Web Development</h6>
                  <p className="justify-center text-center">
                    Website development plays a very important role in expanding
                    online business.
                  </p>
                </div>
                {/* <div class="sl-icons">
                  <FaForumbee className="ml-5" width="50px" />
                </div> */}
              </div>
              <div class="">
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Website Designing</h6>
                  <p className="justify-center text-center">
                    Website designing is an excellent way to boost a business as
                    it attracts large .
                  </p>
                </div>
                {/* <div class="sl-icons">
                <FaForumbee />
                </div> */}
              </div>
              <div class="">
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Applications &amp; User Interfaces</h6>
                  <p className="justify-center text-center">
                    We make user interface design for business Web applications.
                  </p>
                </div>
                {/* <div class="sl-icons">
                <FaForumbee />

                </div> */}
              </div>
              <div class="">
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Responsive Design</h6>
                  <p className="justify-center text-center">
                    We make the Designs in such a manner that fits to every size
                    of devices.
                  </p>
                </div>
                {/* <div class="sl-icons">
                  <FaForumbee />
                </div> */}
              </div>
            </div>
            <div class="col-md-4 d-flex justify-content-center">
              <img
                src={Mobile}
                alt="software"
                width="100%"
                className="d-flex justify-content-center"
              ></img>
            </div>
            <div class="col-md-4">
              <div class=" text-left text-align-left">
                {/* <div class="sl-icons sl-icons2">
                <FaForumbee />

                </div> */}
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Website Hosting</h6>
                  <p className="justify-center text-center">
                    Different of choices available for Domain Registration and
                    Hosting Services.
                  </p>
                </div>
              </div>
              <div class=" text-left">
                {/* <div class="sl-icons sl-icons2">
                <FaForumbee />

                </div> */}
                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">SEO</h6>
                  <p className="justify-center text-center">
                    Search Engine optimization is significant to the success of
                    an online business.
                  </p>
                </div>
              </div>
              <div class=" text-left">
                
                {/* <div class="sl-icons sl-icons2">
                <FaForumbee />
                </div> */}

                <div>
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">E-commerce</h6>
                  <p className="justify-center text-center">We make shopping portal for different shopping sites.</p>
                </div>
              </div>
              <div class=" text-left">

                {/* <div class="sl-icons sl-icons2">
                <FaForumbee />

                </div> */}
               
                  <h6 className="text-rose-700 flex justify-center text-xl font-semibold">Marketing</h6>
                  <p className="justify-center text-center ">
                    Internet marketing, is what you can do with websites, online
                    advertising, other online marketing opportunities.
                  </p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Heading;
